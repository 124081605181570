.footerContainer {
}
.footer-rect {
  height: 75px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-around;
  /* Adjust spacing between SVG icons */
  align-items: center;
  padding: 0 10vw;
  /* Adjust horizontal padding inside the footer */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.footer-bottom {
  width: calc(100vw);
  /* Adjust size as needed, subtracting margin */
  height: 75px;
}

.footer-icon {
  width: 34px;
  height: 34px;
  fill: black;
  /* Add any additional styles for SVG icons */
  margin: 0 10px;
  /* Adjust spacing between SVG icons */
}

.circle {
  position: absolute;
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  margin-top: -15px;
  margin-left: 20px;

  /* Just making it pretty */
  background: #38a9e4;
  color: white;
  font-family: Helvetica, Arial Black, sans;
  font-size: 20px;
  text-align: center;
}
.scanMeja {
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(143, 135, 135);
  color: #fff;
  width: 147px;
  height: 40px;
  border-radius: 25px;
  padding: 0px 10px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: height 0.3s ease;
  z-index: 197;
}

.scanMeja.stretched {
  height: 70px;
  flex-direction: column;
  padding: 10px;
}

.qrIcon {
  top: 2px;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: rgb(143, 135, 135);
}

.hapusMejaBtn {
  margin-top: 10px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}
