.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: -1px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  width: 80vw;
  max-height: 80vh;
  position: relative;
  overflow: visible; /* Add this line to enable scrolling */
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  padding: 0;
}

.closeButton:hover {
  color: #f44336; /* Change color on hover for better UX */
}
