.item-type-lister {
  width: 100vw;
  overflow-x: auto;
  white-space: nowrap;
  padding: 3px 0px;
  margin-bottom: -5px;
}

.item-type-list {
  display: inline-flex;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: hidden;
}

.item-type {
  display: inline-block;
  margin-right: 20px;
  /* Space between items */
}
.rect-creator {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  z-index: 300;
  display: flex;
  flex-direction: column;
}

.inline-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Always 4 columns */
  gap: 10px; /* Spacing between grid items */
  padding: 10px; /* Padding inside grid */
  overflow-y: auto; /* Allow scrolling if items overflow */
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Always 4 columns */
  gap: 10px; /* Spacing between grid items */
  padding: 10px; /* Padding inside grid */
  max-height: calc(3 * (25vw - 20px) + 20px); /* 3 items + gaps */
  overflow-y: auto; /* Allow scrolling if items overflow */
  padding-top: 15px;
  height: calc(43vw - 20px);
}

.add-button {
  margin: 10px; /* Margin around the button */
  padding: 10px 20px; /* Padding for the button */
  position: absolute; /* Optional, for styling */
  bottom: 0;
  align-self: center; /* Center the button horizontally */
}
.item-type-name {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  height: 20vw;
  font-size: 1.5rem;
  font-weight: 500;
  color: black;
  text-transform: capitalize;
  z-index: 301;
}
