.qrisReaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0; /* Example background color */
  overflow: hidden;
}
.qrReader {
  transform: scaleX(-1); /* Flip horizontally */
}
.qrScannerContainer {
  position: relative;
  width: 100%; /* Full width */
}

.qrScannerContainer section {
  position: relative;
  overflow: hidden;
}

.qrScannerContainer video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure video fills the entire container */
  display: block;
  position: absolute;
  transform: scaleX(-1); /* Flip video horizontally */
}

.focusSquare {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw; /* Adjust width as needed */
  height: 50vw; /* Adjust height as needed */
  border: 2px solid rgb(75, 75, 75); /* Example border for visibility */
}

.inputContainer {
  position: absolute;
  width: 300px;
  height: 40px;
  padding: 10px;
  bottom: 50px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.inputContainer input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc; /* Example border */
  border-radius: 4px; /* Example border radius */
  margin-right: 10px; /* Example margin */
}

.inputContainer button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff; /* Example button color */
  color: #fff; /* Example text color */
  border: none;
  border-radius: 4px; /* Example border radius */
  cursor: pointer;
}
