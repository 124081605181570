.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  position: relative;
  z-index: 201; /* Make sure the button is above the replica */

  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 70%; /* Adjusted for better readability */
  padding: 12px 24px; /* Added padding for a better look */
  border-radius: 50px;
  background-color: rgba(88, 55, 50, 1);
  color: white;
  border: none;
  margin: 0 auto;
  cursor: pointer;
  align-items: center;
  text-align: center;
  display: inline-flex;
}

.replica {
  height: 40px;
  width: 140px;
  border-radius: 30px;
  position: absolute;
  background-color: rgba(146, 146, 146, 0); /* Semi-transparent blue */
  border: none;
  transition: all 0.5s ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 98; /* Behind the button */
}

.replica.active {
  width: 200vw; /* Full screen */
  height: 200vh; /* Full screen */
  position: absolute;
  overflow-y: hidden;
  top: 30%;
  z-index: 200;
  border-radius: 0px;
  background-color: white;
  pointer-events: none; /* Allow interactions */
}
.bussinessName {
  position: absolute;
  top: 50%;
  left: 50%;
  color: rgba(0, 0, 0, 0);
  transform: translate(-50%, -50%);
  z-index: 98; /* Behind the button */
  transition: all 0.5s ease-in-out;
  font-size: 3vw;
  text-align: center;
  pointer-events: none;
}

.bussinessName.active {
  top: -900%;
  position: absolute;
  color: rgb(0, 0, 0);
  width: 60vw;
  right: 50%;
  z-index: 201; /* Behind the button */
  font-size: 3vw;
  text-align: center;
  transition: all 0.5s ease-in-out;
}
.bussinessName h2 {
  overflow-wrap: break-word;
  color: inherit; /* Ensures that it inherits the color from the parent */
}
.bussinessQR {
  position: absolute;
  height: 480%;
  width: 480%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 98; /* Behind the button */
  transition: all 0.5s ease-in-out;
}

.bussinessQR.active {
  position: absolute;
  top: -321%;
  transform: translate(-50%, -80%);

  transition: all 0.5s ease-in-out;
  z-index: 201; /* Behind the button */
}

.price {
  position: absolute;
  top: 50%;
  left: 50%;
  color: rgba(0, 0, 0, 0);
  transform: translate(-50%, -50%);
  z-index: 98; /* Behind the button */
  transition: all 0.5s ease-in-out;
  font-size: 3vw;
  text-align: center;
}

.price.active {
  position: absolute;
  color: rgb(0, 0, 0);
  transform: translate(-50%, -150%);
  z-index: 201; /* Behind the button */
  font-size: 3vw;
  text-align: center;
}

.ClaimButton {
  visibility: hidden;
  position: absolute;
}

.ClaimButton.active {
  visibility: visible;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 70%; /* Adjusted for better readability */
  padding: 12px 24px; /* Added padding for a better look */
  border-radius: 50px;
  background-color: rgba(88, 55, 50, 1);
  color: white;
  border: none;
  margin: 0 auto;
  cursor: pointer;
  display: block; /* Centering the button */
  text-align: center;
  z-index: 201;
}
