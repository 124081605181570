/* SetPaymentQr.module.css */

.container {
    width: 100%;
    min-height: 47vh;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .title {
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .uploadMessage {
    font-weight: 600;
    text-align: left;
  }
  
  .changeButtonEnabled {
    padding-right: 10px;
    background-color: green;
    border-radius: 30px;
    color: white;
    font-weight: 700;
    height: 36px;
    line-height: 36px;
    padding-left: 10px;
    padding-height: 10px;
  }
  
  .changeButtonDisabled {
    padding-right: 10px;
    background-color: #a1a1a1;
    border-radius: 30px;
    color: white;
    font-weight: 700;
    height: 36px;
    line-height: 36px;
    padding-left: 10px;
    padding-height: 10px;
  }
  
  .resultMessage {
    margin-top: -13px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
  
  .stokContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -20px;
    margin-bottom: -15px;
    text-align: left;
  }
  
  .buttonContainer {
    margin-top: 11px;
    text-align: left;
  }
  
  .stockButton {
    padding: 10px 20px;
    font-size: 3.5vw;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .saveButton {
    width: 100%;
    padding: 10px 20px;
    font-size: 3.5vw;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .switchContainer {
    margin-top: 20px;
    text-align: left;
  }
  
  .historyTab {
    text-align: left;
  }
  
  .historyContainer {
    text-align: left;
    max-height: 15vh;
    overflow-y: auto;
  }
  
  .description {
    display: flex;
    margin: 10px 0;
    font-size: 14px;
    color: #666;
  }
  
  .unit {
    margin-top: 11px;
    width: 100%;
    height: 31px;
  }
  
  .sorter {
    border: 1px solid #c3c3c3;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  
  .mutationCard {
    display: flex;
    margin-bottom: 7px;
    margin-top: 7px;
  }
  
  .mutationTitle {
    margin-left: 5px;
  }
  .mutationTitle h4 {
    margin: 0;
  }
  
  .mutationTitle p {
    margin: 0;
  }