.Transaction {
  overflow-x: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: calc(8px + 2vmin);
  color: rgba(88, 55, 50, 1);
  background-color: #e9e9e9;
  border-radius: 15px;
  max-height: 87vh;
  width: 80vw;
}
.Transactions {
  overflow-x: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: rgba(88, 55, 50, 1);
  background-color: #e9e9e9;
}

.Transactions-title {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 6vw;
  color: black;
  text-align: left;
  margin-left: 20px;
  margin-top: 57px;
}

.Transactions-detail {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  color: rgba(88, 55, 50, 1);
  text-align: left;
  margin-left: 20px;
  margin-top: 17px;
}

.TransactionListContainer {
  overflow-y: auto; /* Enables vertical scrolling */
  background-color: #dbdbdb;
  overflow: visible;
}

.TotalContainer {
  display: flex
  ;
      justify-content: space-between;
      /* width: 100%; */
      margin: 0 auto;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      /* padding: 10px; */
      box-sizing: border-box;
      margin-bottom: 17px;
      margin-left: 20px;
      margin-right: 20px;
}

.PaymentContainer {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensures it takes up full width */
  margin: 0 auto;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  padding: 10px;
  box-sizing: border-box; /* Includes padding in width */
  justify-content: center;
}

.PayButton {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px; /* Adjusted for better readability */
  padding: 12px 16px; /* Added padding for a better look */
  border-radius: 50px;
  background-color: rgba(88, 55, 50, 1);
  color: white;
  border: none;
  margin: 0 auto;
  cursor: pointer;
  display: block; /* Centering the button */
  text-align: center;
}
.DeclineButton {
  font-family: "Plus Jakarta Sans", sans-serif;
  z-index: 201;
  position: relative;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  padding: 12px 24px; /* Add some padding for spacing */
  color: rgba(88, 55, 50, 1);
  background-color: transparent; /* No background */
  border: none; /* No border */
  margin: 0 auto; /* Center horizontally */
  cursor: pointer;
  display: block; /* Center the text horizontally */
  text-align: center; /* Center the text within the button */
}

.DeclineButton.active {
  position: relative;
  z-index: 201;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  padding: 12px 24px; /* Add some padding for spacing */
  color: rgba(88, 55, 50, 1);
  background-color: transparent; /* No background */
  border: none; /* No border */
  margin: 0 auto; /* Center horizontally */
  cursor: pointer;
  display: block; /* Center the text horizontally */
  text-align: center; /* Center the text within the button */
  margin-bottom: 23px; /* Space at the bottom to match the PayButton */
}
.RoundedRectangle {
  position: relative;
  border-radius: 20px;
  padding: 15px; /* Adjusted for better spacing */
  margin: 26px;
  background-color: #f9f9f9;
}

.expression {
  width: 100%;
}

.Note {
  text-align: left;
  color: rgba(88, 55, 50, 1);
  font-size: 1em;
  cursor: pointer;
}

.NoteContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1em;
  margin-bottom: 15px;
}

.NoteInput {
  height: 12vw;
  border-radius: 20px;
  margin: 0 auto;
  padding: 10px;
  font-size: 1.2em;
  border: 1px solid rgba(88, 55, 50, 0.5);
  resize: none; /* Prevent resizing */
  overflow-wrap: break-word; /* Ensure text wraps */
}

.RibbonBanner {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 200px;
  height: 200px;
  left: -18px;
}

.RibbonBanner img {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.RibbonBanner h1 {
  margin: 0; /* Remove default margin */
  font-size: 20px; /* Adjust font size as needed */
  transform: rotate(-44.7deg); /* Rotate the text */
  transform-origin: center; /* Rotate around its center */
  white-space: nowrap; /* Prevent text wrapping */
  position: absolute;
  top: 60px;
  left: -15px;
}

/* Header with logo and receipt info */
.receipt-header {
  text-align: center;
}

.receipt-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%; /* Circular logo */
  object-fit: cover;
  margin-bottom: 10px;
}

.receipt-info h3 {
  font-size: 16px;
  margin: 5px 0;
}

.receipt-info p {
  font-size: 14px;
  margin: 2px 0;
}
/* Dotted line with circular cutouts */
.dotted-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.dotted-line .line {
  border-top: 13px dotted #dbdbdb;
  width: 100%;
  margin: 0 18px;
}

.dotted-line .circle-left {
  left: -25px;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #dbdbdb;
  display: flex; /* Use flexbox to center the inner circle */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.dotted-line .circle-right {
  right: -25px;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #dbdbdb;
  display: flex; /* Use flexbox to center the inner circle */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.inner-circle {
  width: 80%;
  height: 80%;
  border-radius: 50%; /* Make it a circle */
  background-color: white; /* Background color of the inner circle */
}


.inner-text {
  font-size: 24px; /* Adjust the font size as needed */
  color: white; /* Text color */
}


.circle {
  position: absolute;
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  margin-top: -43px;
  margin-left: 43px;

  /* Just making it pretty */
  background: #38a9e4;
  color: white;
  font-family: Helvetica, Arial Black, sans;
  font-size: 20px;
  text-align: center;
}


.dateSelectorWrapper {
  display: flex;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  width: calc(100vw - 50px);
}

.dateSelector {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  width: calc(30vw - 30px);
}

.dateSelector:first-child {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
  /* border-radius: 10px 0 0 0; */
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.dateSelector:last-child {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
  /* border-radius: 0 10px 0 0; */
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.dateSelectorActive {
  color: black;
  z-index: 2;
}

.couponContainer {
  padding: 25px;
  transition: margin-top 0.3s ease;
}

.addCoupon {
  border: 2px solid #ccc;
  background-color: #f8f8f8;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  height: 52px;
}


.filterSelectorWrapper {
  display: flex;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
  width: calc(100vw - 60px);
  margin: 10px 30px 10px 30px;
}
.filterSelector {
  flex-grow: 1;
  padding: 6px;
  width: calc(30vw - 30px);
  background-color: #00000045;
  border-radius: 20px;
  font-size: 14px;
  margin: 3px;
}
.filterSelectorInactive {
  flex-grow: 1;
  padding: 6px;
  width: calc(30vw - 30px);
  border-radius: 20px;
  font-size: 14px;
  margin: 3px;
  background-color: white;
  border: 1px solid black;
}