.music-player {
  position: relative;
  width: 95%;
  margin: 7px auto 20px;
  /* Added padding for top and bottom */
  color: white;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 15px;
  /* Add border-radius to the music player container */
  transition: height 0.3s ease;
}

.current-bgr {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  /* Adjust height as needed */
  background-size: cover;
  /* Adjust background image size */
  background-position: center;
  /* Center the background image */
  /* filter: blur(1.5px);
  -webkit-filter: blur(1.5px); */
  /* border-radius: 13px 13px 0 0; */
  background-color: rgb(95 121 89);
  /* Rounded corners at the top */
  text-align: right;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

  border-radius: 0 0 15px 15px;
  z-index: 1;
}

.current-name {
  white-space: nowrap;
  pointer-events: none;
  position: relative;
  z-index: 2;
  text-align: left;
  margin: 35px 30px;
  font-size: 16px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  /* Text shadow for readability */
}

/* styles.css */
@keyframes slideAnimation {
  0% {
    margin-left: 100vw;
  }
  10% {
    margin-left: 30px;
  }
  70% {
    margin-left: 30px;
  }
  100% {
    margin-left: -100vw;
  }
}

.animated-text {
  animation: slideAnimation 3s linear infinite; /* 4s duration for the animation */
  white-space: nowrap; /* Prevent text from wrapping */
}

.current-artist {
  pointer-events: none;
  position: relative;
  z-index: 2;
  text-align: left;
  margin: -32px 30px;
  font-size: 18px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  /* Text shadow for readability */
  
  margin-bottom: 25px;
}

.progress-container {
  pointer-events: none;
  position: relative;
  z-index: 2;
  text-align: left;
  margin: 12px 30px;
}

.progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 27px;
  /* Adjusted padding for spacing */
}

.current-time,
.track-length {
  font-size: 14px;
  width: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  /* Text shadow for readability */
}

.progress-bar {
  flex-grow: 1;
  margin: 0 10px;
}

.expandable-container {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
  /* Smooth transition for max-height and padding */
  
  /* Example background color */
}

.expandable-container.expanded {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
  max-height: 400px;
  /* Adjust the max-height as needed */
  overflow-y: auto;
  /* Allow vertical scrolling */
}

.expandable-container > div:first-child {
  padding-top: 20px;
  
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
*/ }

.expand-button {
  font-size: 20px;
  position: relative;
  left: 0;
  right: 0;
  /* background-color: rgb(29, 185, 84); */
  background-color: #73a585;
  /* background-color: rgb(218 163 99); */
  border-radius: 0 0 13px 13px;
  /* Rounded corners at the bottom */
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  /* Center text vertically */

  -webkit-tap-highlight-color: transparent;

  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

  outline: none;
  
  transition: padding-top 0.3s ease;
  padding-top: 8px;
  }

.expand-button h5 {
  font-weight: 500;
  margin-top: 0px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.36);
}

.expand-button.expanded{
  padding-top: 2px;
  margin-top: -6px;
}

/* Adjust height of the music player container when expanded */
.music-player.expanded {
  height: auto;
  /* Automatically adjust height based on content */
}

.search-box {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 0px;
}

.search-box input[type="text"] {
  flex-grow: 1;
  border: none;
  border-radius: 25px;
  padding: 10px 15px;
  font-size: 16px;
  outline: none;
  background-color: #f4efe6;
  text-align: center;
  transition: background-color 0.3s ease; /* Smooth transition for the effect */
}

.search-box input[type="text"].clicked {
  background-color: #d0c7b3; /* The color when clicked */
}


.search-box input[type="text"]::placeholder {
  font-weight: bold;
  opacity: 0.7;
  text-align: center;
}

.search-box .search-icon {
  margin-right: 5px;
  color: #888;
  font-size: 20px;
  /* Adjust icon size */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  width: 24px;
  /* Set width for icon */
  height: 24px;
  /* Set height for icon */
  fill: #888;
  /* Adjust fill color */
}

.auth-box {
  display: flex;
  align-items: center;
  padding: 10px;
}

.auth-box div {
  flex-grow: 1;
  border: none;
  border-radius: 25px;
  /* Round the corners */
  padding: 10px 15px;
  font-size: 16px;
  outline: none;
  background-color: #019863;
  /* Remove default outline */
}

.config-box {
  display: flex;
  align-items: center;
  padding: 10px;
}
.config-box > div:first-child {
  flex-grow: 1;
  border: none;
  border-radius: 25px; /* Round the corners */
  padding: 4px 15px;
  font-size: 16px;
  outline: none;
  background-color: #019863; /* Set background color */
  display: flex;
  align-items: center;
  margin-top: -11px;
}
.rectangle {
  position: relative;
  min-height: 200px;
  overflow: hidden;
  border: 3px dashed #fbebcd;
  border-radius: 11px 11px 0 0;

  /* Flexbox for centering */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center vertically */
  background-color: white;
  z-index: 1;
  overflow-y: auto;
}

.middle-text {
  text-align: center;
  font-family: Arial, sans-serif; /* Optional font */
  color: #333; /* Optional text color */
  margin: 25px;
}

.bold-text {
  font-weight: bold;
  font-size: 18px;
}

.normal-text {
  font-weight: normal;
  font-size: 13px;
}

.search-button {
  display: block;
  margin: 1rem auto 0;
  flex-grow: 1;
  border: none;
  border-radius: 25px;
  padding: 10px 15px;
  font-size: 16px;
  outline: none;
  background-color: #f4efe6; /* Original color */
  color: black;
  font-weight: bold;
  transition: background-color 1s ease; /* Smoothly transition background color over 1 second */
}

.search-button.clicked {
  background-color: #d0c7b3; /* The color when clicked */
}

