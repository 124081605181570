/* ItemLister.module.css */

.item-lister {
  width: 100%;
  padding: 10px; /* Adjust padding as needed */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  white-space: break-spaces;
}

.fullscreen {
  position: fixed; /* Keep the container fixed */
  top: 0; /* Adjust the top position as needed */
  bottom: 0; /* Occupy the full height of the viewport */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  background-color: white; /* Background color */
  z-index: 1000; /* Layering */
  overflow-y: auto; /* Allow vertical scrolling */
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  /* padding: 10px; */
  /* max-height: calc(3 * (25vw - 20px) + 20px); */
  overflow-y: auto;
  height: calc(49vw - 20px);
}

@media (min-height: 0px) {
  .grid-container {
    height: 27vh;
  }
}
@media (min-height: 630px) {
  .grid-container {
    height: 27vh;
  }
}
@media (min-height: 636px) {
  .grid-container {
    height: 29vh;
  }
}
@media (min-height: 650px) {
  .grid-container {
    height: 34vh;
  }
}
@media (min-height: 705px) {
  .grid-container {
    height: 37vh;
  }
}
@media (min-height: 735px) {
  .grid-container {
    height: 38vh;
  }
}
@media (min-height: 759px) {
  .grid-container {
    height: 40vh;
  }
}
@media (min-height: 819px) {
  .grid-container {
    height: 44vh;
  }
}

@media (min-height: 830px) {
  .grid-container {
    height: 47vh;
  }
}
@media (min-height: 892px) {
  .grid-container {
    height: 49vh;
  }
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  background-color: transparent;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: rgba(88, 55, 50, 1);
    text-align: left;
    width: calc(70% - 10px);
    padding-left: 10px;
    text-transform: capitalize;
}

.edit-typeItem-button {
  margin-left: auto; /* Push the button to the right */
  padding: 8px 16px; /* Adjust padding as needed */
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-item-button {
  margin-top: 10px;
  padding: 8px 16px; /* Adjust padding as needed */
  font-size: 14px;
  background-color: #359d42d1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.item-list {
  display: flex;
  flex-direction: column; /* Display items in a column */
}

.border {
  border: 1px solid #000000;
}

.noborder {
  border: 1px solid #ffffff00;
}
.itemWrapper {
  position: relative;
}
.editModeLayout {
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  background-color: #0000008c;
  width: 100%;
  top: 7px;
  bottom: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PaymentOption {
  overflow-x: hidden;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(88, 55, 50, 1);
  border-radius: 15px 15px 0 0;

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: 300;
}

.TotalContainer {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5em;
  padding: 10px 0;
}
.OptionContainer {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.9em;
  padding: 10px 0;
}
.PayButton {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 32px;

  width: 80vw;
  height: 18vw;
  border-radius: 50px;
  background-color: rgba(88, 55, 50, 1);
  color: white;
  border: none;
  margin: 0px auto;
  cursor: pointer;
  margin-bottom: 23px;
}

.Pay2Button {
  text-align: center;
  color: rgba(88, 55, 50, 1);
  font-size: 1em;
  margin-bottom: 25px;
  cursor: pointer;
}

.item-list-title {
  margin-top: 46px;
  margin-left: 0px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 6vw;
  color: black;
  text-align: left;
}