/* AccountUpdatePage.module.css */

/* General container styles */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Heading styles */
h2 {
  text-align: center;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

/* Form styles */
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Label and input styles */
.formLabel {
  font-size: 1rem;
  color: #555;
  font-weight: 600;
}

.formInput {
  padding: 10px;
  margin-top: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.3s ease;
}

.formInput:focus {
  border: 1px solid #007BFF;
  outline: none;
}

/* Submit button styles */
.submitButton {
  padding: 12px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #0056b3;
}

/* Mobile responsive design */
@media (max-width: 768px) {
  .container {
      padding: 15px;
      width: 90%;
  }

  h2 {
      font-size: 1.6rem;
  }

  .formInput {
      font-size: 0.9rem;
  }

  .submitButton {
      padding: 10px;
      font-size: 0.95rem;
  }
}

/* Tablet and desktop view adjustments */
@media (min-width: 769px) {
  .container {
      padding: 20px;
      width: 50%;
  }

  h2 {
      font-size: 2rem;
  }

  .formInput {
      font-size: 1rem;
  }

  .submitButton {
      padding: 12px;
      font-size: 1.1rem;
  }
}

/* Additional Accessibility Enhancements */
.formInput:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
/* AccountUpdatePage.module.css */

/* Success message styles */
.successMessage {
  color: green;
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

/* Error message styles */
.errorMessage {
  color: red;
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}
