/* GuidePage.css */
.guide-page {
    padding: 16px;
    font-size: 16px;
    line-height: 1.5;
    background-color: #f9f9f9;
    color: #333;
    max-width: 600px;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  
  p {
    margin-bottom: 16px;
  }
  
  .guide-video {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 16px;
  }
  